import styled from '../styles/styled'
import LocalizedLink from './translations/LocalizedLink'

const LocalizedButton = styled(LocalizedLink)`
  display: inline-block;
  background: ${props => props.theme.colors.yellow};
  text-align: center;
  border-radius: 3px;
  font-family: ${props => props.theme.fonts.headerFont};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${props => props.theme.colors.black};
  font-size: 13px;
  padding: 12px 15px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    font-size: 18px;
    padding: 12px 35px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export default LocalizedButton
