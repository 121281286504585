import * as React from 'react'
import styled from '../../styles/styled'

import { transparentize } from 'polished'
import LocalizedLink from '../translations/LocalizedLink'
import Container from '../layouts/Container'
import Page from '../layouts/Page'
import TabContentContainer, { tabLayout, TabSubjects } from './TabContentContainer'
import { IntroQuery, ProjectDetailQuery } from '../../graphql-types'
// @ts-ignore
import i18n from '../../../config/i18n.js'
import GoToMapButton from '../projects/GoToMapButton'

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 800px;
  width: 100%;
  align-content: flex-start;
`

const StyledTabHeader = styled.div`
  flex: 0 0 ${props => props.theme.heights.tabHeader}px;
  height: ${props => props.theme.heights.tabHeader}px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    height: 65px;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: auto;
  background-color: ${props => props.theme.colors.blue};
`

type StyledTabLinkProps = { to: string; active: boolean }
const StyledTabLink = styled(LocalizedLink)<StyledTabLinkProps>`
  color: ${props => (props.active ? 'white' : transparentize(0.4, props.theme.colors.white))};
  display: block;
  font-size: 1rem;
  line-height: 30px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    line-height: 45px;
  }
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 35px 7px;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }
`

const StyledTabItem = styled.div<StyledTabItemProps>`
  border-bottom: ${props => (props.active ? `3px solid ${props.theme.colors.white}` : 'none')};
`

const StyledCloseLink = styled(LocalizedLink)`
  display: flex;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 15px 25px;
  color: ${props => props.theme.colors.white};
  margin-left: auto;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }
  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
`

const StyledTabContentContainer = styled.div`
  background-color: ${transparentize(0.2, '#0A0A0A')};
  flex: 1 1 auto;
  overflow: auto;
`

interface StyledTabItemProps {
  active: boolean
}

interface Item {
  id: string
  title: string
  locale: string
  to: string
}

interface TabProps {
  subject: string
  tabData: IntroQuery
  tabOrder: string[]
  activeTab: string
}

interface TabItemProps {
  item?: Item
  active: boolean
}

const DEFAULT_TITLE = 'no title'

const TabItem: React.FC<TabItemProps> = ({ item, active }) => {
  return item ? (
    <StyledTabItem active={active}>
      <StyledTabLink to={item.to} active={active}>
        {item.title}
      </StyledTabLink>
    </StyledTabItem>
  ) : null
}

const ProjectLinkContainer = styled.div`
  text-align: right;
  padding: 0 35px 35px;
`

const Index: React.FC<TabProps> = ({ tabOrder, children, subject, tabData, activeTab }) => {
  const { allMdx, mdx } = tabData

  const introductionTabItems = allMdx.edges.map(({ node }: ProjectDetailQuery['allMdx']['edges'][0]) => ({
    id: node.id,
    title: node?.frontmatter?.title ?? DEFAULT_TITLE,
    locale: node?.fields?.locale ?? i18n.de.siteLanguage,
    to: `/${node?.parent?.relativeDirectory! ?? 'no-slug/'}`
  }))

  const localizedIntroductionTabItems = introductionTabItems.filter(({ locale }) => locale === mdx?.fields?.locale)

  const orderedTabItems = tabOrder.map(tabName => {
    return localizedIntroductionTabItems.find(item => item.to.includes(tabName))
  })

  const typedSubject = (subject: string): TabSubjects => {
    return Object.keys(tabLayout).includes(subject) ? (subject as TabSubjects) : 'fallBack'
  }

  return (
    <Page>
      <Container>
        <StyledTabContainer>
          <StyledTabHeader>
            {orderedTabItems.map(item => {
              return <TabItem key={item?.id} item={item} active={item?.id === activeTab} />
            })}
            <StyledCloseLink to="/projects/">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <title>cross</title>
                {/* eslint-disable-next-line max-len */}
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
              </svg>
            </StyledCloseLink>
          </StyledTabHeader>

          <StyledTabContentContainer>
            <TabContentContainer subject={typedSubject(subject)}>{children}</TabContentContainer>
            <ProjectLinkContainer>
              <GoToMapButton />
            </ProjectLinkContainer>
          </StyledTabContentContainer>
        </StyledTabContainer>
      </Container>
    </Page>
  )
}

export default Index
