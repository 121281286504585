import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Index from '../components/Tab'
import { ProjectDetailQuery } from '../graphql-types'

interface ProjectProps {
  data: ProjectDetailQuery
}

export const tabOrder = ['info', 'case-study', 'quiz', 'materials']
const DEFAULT_BODY = 'no body'

const ProjectDetail: React.FC<ProjectProps> = (props: ProjectProps) => {
  const {
    data: { mdx }
  } = props

  return (
    <Index tabOrder={tabOrder} tabData={props.data} activeTab={mdx?.id ?? ''} subject={mdx?.fields?.subject ?? ''}>
      <MDXRenderer>{mdx?.body ?? DEFAULT_BODY}</MDXRenderer>
    </Index>
  )
}

export default ProjectDetail

export const query = graphql`
  query ProjectDetail($locale: String!, $title: String!, $project: String!) {
    allMdx(filter: { frontmatter: { template: { in: ["project-detail", "project-info"] } }, fields: { project: { eq: $project } } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            locale
            isDefault
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale }, project: { eq: $project } }) {
      id
      body
      fields {
        locale
        subject
      }
      frontmatter {
        title
      }
    }
  }
`
