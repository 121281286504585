import * as React from 'react'
import styled from '../../styles/styled'
// @ts-ignore
import backgroundSVG from '../../images/bubble_background.svg'

interface TabLayouts {
  background: string
  'about-us': string
  tutorial: string
  info: string
  'case-study': string
  materials: string
  quiz: string
  fallBack: string
}

export type TabSubjects = keyof TabLayouts

const background = `
  div:first-of-type {
    color: #fff;
    column-count: 2;
    column-gap: 40px;
    background: url(${backgroundSVG}) no-repeat center right;
    background-size: contain;
  }
`

export const tabLayout: Partial<TabLayouts> = {
  background,
  'about-us': background,
  fallBack: ''
}

const StyledTabContentContainer = styled.div<ContainerProps>`
  position: relative;
  padding: 35px;
  overflow: auto;
  h2 {
    color: #fff;
    margin-bottom: 32px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  ${props => tabLayout[props.subject]}
`

interface ContainerProps {
  subject: TabSubjects
}

const Container: React.FC<ContainerProps> = ({ children, subject }) => {
  return <StyledTabContentContainer subject={subject}>{children}</StyledTabContentContainer>
}

export default Container
