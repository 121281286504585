import * as React from 'react'
import LocalizedButton from '../Button'
import { useTranslation } from 'react-i18next'

type Props = {
  className?: string
}

const GoToMapButton = ({ className }: Props) => {
  const { t } = useTranslation()

  return (
    <LocalizedButton className={className} to={'/projects'}>
      {t(`goToButton`)}
    </LocalizedButton>
  )
}

export default GoToMapButton
